<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="adminUserList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage" class="p-datatable-users" data-key="maa1" :rowHover="true"
                    v-model:expandedRows="expandedRows"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :style="{ 'overflow-wrap': 'break-word' }">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Admin List</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Add Admin" icon="pi pi-plus" @click="addNewUserDialogOpen" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait... </template>
                    <Column field="name" header="Admin Name" headerStyle="width: 40%">
                        <template #body="{ data }">
                            <div class="p-d-flex p-ai-center">
                                <div class="p-mr-3">
                                    <span v-if="data.maa6 != null && data.maa6 != ''">
                                        <img :src="'https://storage.googleapis.com/' + storage_path + '/AdminUser/Logo/' + data.maa6"
                                            width="50" height="50" style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                    <span v-else>
                                        <img src="/assets/layout/images/widgets/Image_not_available.png" width="50"
                                            height="50" style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                </div>
                                <div>
                                    <div class="p-text-capitalize" v-tooltip.right="'Admin Name'">{{ data.maa2 ? data.maa2
                                        : 'N/A' }}</div>
                                    <small class="muted-text p-text-capitalize"
                                        v-tooltip.right="'Activited Date'">28/08/2022</small>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <!-- <Column header="Host Name" headerStyle="width: 35%">
                        <template #body="{ }">
                            <div>
                                <div class="p-text-capitalize">
                                    <span v-tooltip.right="'Hosts Name'">
                                         <span v-if="data.maa16">
                                            <span v-for="(value, index) in data.maa16.split(',')" :key="index">
                                                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" :label="value" />
                                            </span>
                                        </span>
                                        <span v-else>
                                            N/A
                                        </span> 
                                        <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts">North Goa</Chip>
                                        <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts">South Goa</Chip>
                                        <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts">East Goa</Chip>
                                        <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts">West Goa</Chip>
                                    </span>
                                </div>
                            </div>
                        </template>
                    </Column> -->
                    <Column header="Contact Details" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div>
                                <div>+91 {{ data.maa17 ? data.maa17 : 'N/A' }}</div>
                                <div class="p-mt-1"><small class="muted-text overflow-span"> {{ data.maa18 ? data.maa18 : 'N/A' }}</small>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Status" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <span v-if="data.maa37 == 0" :class="'user-badge status-active'">Active</span>
                            <span v-if="data.maa37 == 1" :class="'user-badge status-temp-suspend'">Soft
                                Suspension</span>
                            <span v-if="data.maa37 == 2" :class="'user-badge status-temp-suspend'">Temporary
                                Suspension</span>
                            <span v-if="data.maa37 == 3" :class="'user-badge status-temp-suspend'">Suspended</span>
                            <span v-if="data.maa37 == 4" :class="'user-badge status-per-suspend'">Deletion Notice
                                Period</span>
                            <span v-if="data.maa37 == 5" :class="'user-badge status-per-suspend'">Permanent
                                Suspended</span>
                            <span v-if="data.maa37 == 6" :class="'user-badge status-per-suspend'">Deleted</span>
                            <span v-if="data.maa37 == 7" :class="'user-badge status-temp-suspend'">Under
                                Maintenance</span>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <Button icon="pi pi-user" v-tooltip.left="'Login As Admin'" severity="info"
                                class="p-button-rounded p-button-info" @click="loginAsadminDialogOpen(data)" />
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-ml-1"
                                @click="editadminDetailsRedirect(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add admin dialog start here -->
    <Dialog v-model:visible="addUserDialogStatus" :style="{ width: '1100px' }" header="Add" :modal="true" class="p-fluid">
        <template #header>
            <div class="inline-flex align-items-center justify-content-center gap-2">
                <span class="font-bold white-space-nowrap p-dialog-title">Add Admin</span>
            </div>
        </template>
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="adminname">
                        Admin Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="adminname" class="p-text-capitalize" v-model.trim="adminname" required="true" autofocus
                        :class="{ 'p-invalid': submitted && !adminname }" />
                    <small class="p-invalid p-error" v-if="v$.adminname.$error">{{
                        v$.adminname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="adminshortname">
                        Admin Short Name
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <InputText id="adminshortname" class="p-text-capitalize" v-model.trim="adminshortname" required="true" />
                    <!-- <small class="p-invalid p-error" v-if="v$.adminshortname.$error">{{
                        v$.adminshortname.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="officemobileno">Office Contact Number <span class="p-invalid p-error">*</span></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            +91
                        </span>
                        <InputText id="officemobileno" v-model="officemobileno" autocomplete="off" maxlength="10"
                            @keypress="onlyNumber" autofocus :class="{ 'p-invalid': submitted && !officemobileno }" />
                    </div>

                    <small class="p-invalid p-error" v-if="v$.officemobileno.$error">{{
                        v$.officemobileno.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="officeemail">Office email ID <span class="p-invalid p-error">*</span></label>
                    <InputText id="email" v-model="officeemail" autocomplete="off" maxlength="60" autofocus
                        :class="{ 'p-invalid': submitted && !officeemail }" />
                    <small class="p-invalid p-error" v-if="v$.officeemail.$error">{{ v$.officeemail.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" optionLabel="label" placeholder="Select ..."
                        appendTo="body" :filter="true" @change="selectadmincity"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{ v$.state.$errors[0].$message }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="city">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <AutoComplete id="city" v-model="city" :suggestions="citySuggestions" field="label"
                        placeholder="Select ..." appendTo="body" @complete="searchCities" multiple dropdown />
                    <small class="p-invalid p-error" v-if="v$.city.$error">{{ v$.city.$errors[0].$message }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="officeaddress">
                        Office Address
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Textarea id="officeaddress" class="p-text-capitalize" v-model="officeaddress" :autoResize="true"
                        rows="1" cols="30" required="true" autofocus
                        :class="{ 'p-invalid': submitted && !officeaddress }" maxlength="250" />
                    <small class="p-invalid p-error" v-if="v$.officeaddress.$error">{{
                        v$.officeaddress.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>Invoice Generator Application? </label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="invoice_yes" name="option" v-bind:value="1" v-model="invoicegenerator"
                            :checked="invoicegenerator == 1" />
                        <label for="invoice_yes" class="p-mr-2">Yes</label>
                        <RadioButton id="invoice_no" name="option" v-bind:value="0" v-model="invoicegenerator"
                            :checked="invoicegenerator == 0" />
                        <label for="invoice_no" class="p-mr-2">No</label>
                    </div>
                    <!-- <small class="p-invalid p-error" v-if="v$.invoicegenerator.$error">{{
                        v$.invoicegenerator.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="adminstatus">
                        Admin Status
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="adminstatus" v-model="adminstatus" :options="adminstatusList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.adminstatus.$error">{{
                        v$.adminstatus.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-12">
                            <label for="image">
                                Admin Logo
                                <!-- <span class="p-invalid p-error">*</span> -->
                            </label>
                            <FileUpload mode="basic" ref="adminlogo" :maxFileSize="1000000" accept="image/*"
                                chooseLabel="Upload Admin Logo" auto @select="handleadminLogoUpload" />
                            <small class="p-invalid p-error" v-if="adminlogoerror">{{ adminlogoerror }}</small>
                            <img v-if="adminlogoUrl && adminlogoUrl.url" class="p-pt-2" role="presentation"
                                :alt="adminlogoUrl.name" :src="adminlogoUrl.url" width="120" height="120" />

                            <small class="p-invalid p-error" v-if="adminlogoerror">{{ adminlogoerror
                            }}</small>
                            <!-- <small class="p-invalid p-error" v-if="v$.adminlogo.$error">{{
                                v$.adminlogo.$errors[0].$message
                            }}</small> -->
                        </div>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="storagebucketid">
                        Storage Bucket Id
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="storagebucketid" v-model.trim="storagebucketid" required="true" autofocus
                        :class="{ 'p-invalid': submitted && !storagebucketid }" />
                    <small class="p-invalid p-error" v-if="v$.storagebucketid.$error">{{
                        v$.storagebucketid.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="systemurl">
                        System URL
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="systemurl" v-model.trim="systemurl" required="true" autofocus
                        :class="{ 'p-invalid': submitted && !systemurl }" />
                    <small class="p-invalid p-error" v-if="v$.systemurl.$error">{{
                        v$.systemurl.$errors[0].$message }}</small>
                    <small class="p-invalid p-error" v-if="systemUrlErr">{{ systemUrlErr }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="guests_validation">Guests Validation (In Days)
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <div class="p-inputgroup">
                        <InputText id="guests_validation" v-model="guests_validation" autocomplete="off"
                            maxlength="3" @keypress="onlyNumber" autofocus
                            :class="{ 'p-invalid': submitted && !guests_validation }" />
                    </div>
                    <small class="p-invalid p-error" v-if="v$.guests_validation.$error">{{
                        v$.guests_validation.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-4"> 
                    <label for="system_activation_date">System Activation Date <span
                            class="p-invalid p-error"> *</span></label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true"
                        v-model="system_activation_date" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                        appendTo="body" :manualInput="false"></Calendar>
                    <small class="p-invalid p-error" v-if="v$.system_activation_date.$error">{{
                        v$.system_activation_date.$errors[0].$message }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="createAdminUser($event)" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add admin dialog end here -->

    <!--Login as admin Dialog starts here -->
    <Dialog v-model:visible="loginAsadminDialogStatus" :style="{ width: '70vw' }" modal class="p-fluid"
        header="Admin Login">
        <div style="position: relative;" v-if="!showLoader">
            <div class="p-pt-2 p-px-5">
                <div class="p-flpartyuid p-formgrid p-grid">
                    <div class="p-d-flex p-field p-col-6 p-md-12">
                        <h6 class="p-m-0 ">Select Employee:</h6>
                        <Dropdown id="select-emp" class="p-ml-5 p-text-capitalize" v-model="adminEmployees"
                            :options="adminEmplist" optionLabel="label" placeholder="Select Employee"
                            emptyMessage="No Records" appendTo="body" style="width: 30vw" />
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button v-if="!showLoader" :disabled="adminEmployees == ''" label="Login" icon="pi pi-check"
                class="p-button-text p-ml-auto" style="width: 90px;" @click="loginAsadminRequest"></Button>
            <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>

    <!-- Login as admin Dialog end here -->
</template>
<script>
import ApiService from '../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
import { required, helpers, email, numeric, maxLength, minLength } from '@vuelidate/validators';
import moment from 'moment';

export default {
    data() { 
        return {
            v$: useValidate(),
            adminUserList: [],
            page_no: 0,
            totalRecords: 10,
            loading: false,
            expandedRows: [],
            addUserDialogStatus: false,
            showLoader: false,
            adminname: '',
            adminshortname: '',
            adminlogo: '',
            adminlogoUrl: '',
            adminlogoerror: '',
            officeaddress: '',
            state: '',
            stateList: [],
            city: '',
            cityList: [],
            citySuggestions: [],
            countrycode: { label: '+91', value: 101 },
            countryCodeList: [
                { label: '+91', value: 101 }
            ],
            officemobileno: '',
            officeemail: '',
            invoicegenerator: 1,
            storagebucketid: '',
            adminstatus: { label: 'Active', value: 0 },
            adminstatusList: [
                { label: 'Active', value: 0 },
                { label: 'Soft Suspension', value: 1 },
                { label: 'Temporary Suspension', value: 2 },
                { label: 'Suspended', value: 3 },
                { label: 'Deletion Notice Period', value: 4 },
                { label: 'Permanent Suspended', value: 5 },
                { label: 'Deleted', value: 6 },
                { label: 'Under Maintenance', value: 7 },
            ],
            systemurl: '',
            submitted: false,
            admin_id: '',
            storage_path: '',
            systemUrlErr: '',
            loginAsadminDialogStatus: false,
            adminEmplist: [],
            adminEmployees: '',
            loginsystemurl: '',
            itemsPerPage: 30,
            guests_validation: '',
            system_activation_date: null,
        };
    },
    validations() {
        return {
            adminname: { required: helpers.withMessage('Please enter admin name', required) },
            officeaddress: { required: helpers.withMessage('Please enter office address', required) },
            state: { required: helpers.withMessage('Please select state', required) },
            city: { required: helpers.withMessage('Please select city', required) },
            officeemail: { required: helpers.withMessage('Please enter email address', required), email: helpers.withMessage('Email address is invalid', email) },
            officemobileno: { required: helpers.withMessage('Please enter mobile number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
            adminstatus: { required: helpers.withMessage('Please select admin status', required) },
            storagebucketid: { required: helpers.withMessage('Please enter storage bucket Id', required) },
            systemurl: { required: helpers.withMessage('Please enter system URL', required) },
            guests_validation: { required: helpers.withMessage('Please enter guests validation (In Days)', required) },
            system_activation_date: { required: helpers.withMessage('Please select system activation date', required) },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getAdminUsersList();
        this.getStates();
        this.storage_path = JSON.parse(localStorage.masterBucketName);
    },
    watch: {
        systemurl(systemurl) {
            this.errmsg = "";
            this.validateUrl(systemurl);
            if (systemurl.length === 0) {
                this.systemUrlErr = "";
                this.errStatus = false;
            }
        },
    },
    methods: {
        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },

        searchCities(e) {
            this.citySuggestions = this.cityList.flatMap(d => d.label.toLowerCase().includes(e.query.toLowerCase()) ? [{ label: d.label, value: d.value }] : []);
        },

        selectadmincity(ev) {
            this.getCities(ev.value);
        },

        getCities(e) {
            if (e.value) {
                this.ApiService.getCities({ state_id: e.value }).then((items) => {
                    if (items.status === 200) {
                        this.cityList = items.data;
                    }
                });
            }
        },

        getAdminUsersList(ev) {
            this.loading = true;
            this.ApiService.getAdminUsersList(ev).then(({ data, status }) => {
                if (status == 200) {
                    this.adminUserList = data.result;
                    this.totalRecords = data.count;
                } else {
                    this.adminUserList = '';
                }
                this.loading = false;
            });
        },
        createAdminUser() {
            this.submitted = true;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                let selectedCitiesValues = this.city.map(d => d.value).join();
                let selectedCitiesLabels = this.city.map(d => d.label).join();
                var formData = new FormData();
                formData.append('maa2', this.adminname);
                formData.append('maa6', this.adminlogo);
                formData.append('maa7', this.officeaddress);
                formData.append('maa8', this.state.value);
                formData.append('maa9', this.state.label);
                formData.append('maa10', this.state.value);
                formData.append('maa11', selectedCitiesValues);
                formData.append('maa12', selectedCitiesLabels);
                formData.append('maa17', this.officemobileno);
                formData.append('maa18', this.officeemail);
                formData.append('maa20', this.invoicegenerator);
                formData.append('maa37', this.adminstatus.value);
                formData.append('maa24', this.storagebucketid);
                formData.append('maa25', this.systemurl);
                formData.append('maa4',  moment(this.system_activation_date).format('YYYY-MM-DD') );
                formData.append('maa49', this.guests_validation);
                this.ApiService.createUpdateAdminUser(formData).then((items) => {
                    this.showLoader = true;
                    if (items.status == 200) {
                        this.loading = false;
                        this.submitted = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.admin_id = items.data.maa1;
                        this.addUserDialogStatus = false;
                        this.getAdminUsersList();
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.showLoader = false;
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
                return this.getAdminUsersList({ page_no: this.page_no });
            } 
        },

        handleadminLogoUpload(e) {
            this.adminlogo = e.files[0];
            var adminlogo = e.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!adminlogo) {
                //this.adminlogoerror = 'Please upload logo';
            } else if (!adminlogo.size > 2048) {
                this.adminlogoerror = 'File size cannot exceed more than 2MB';
            } else if (!adminlogo.name.match(allowedExtensions)) {
                this.adminlogoerror = 'Invalid file type';
            } else {
                this.adminlogoerror = '';
                this.adminlogoUrl = { name: this.adminlogo.name, url: this.adminlogo.objectURL };
            }
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAdminUsersList({ page_no: event.page, itemsPerPage: this.itemsPerPage });
        },
        addNewUserDialogOpen() {
            this.submitted = false;
            this.addUserDialogStatus = true;
            this.admin_id = '';
            this.v$.$reset();
        },
        loginAsadminDialogOpen(data) {
            this.submitted = false;
            this.loginAsadminDialogStatus = true;
            this.admin_id = data.maa1;
            this.loginsystemurl = data.maa25;
            this.v$.$reset();
            this.getAdminUsersOnly();
        },
        getAdminUsersOnly() {
            this.ApiService.getAdminUsersOnly({ clientId: this.admin_id }).then((data) => {
                if (data.success === true) {
                    this.adminEmplist = data.data;
                }
            });
        },
        loginAsadminRequest() {
            this.id = this.admin_id;
            this.meraneta_url = 'https://' + this.loginsystemurl;
            this.isSubmitted = true;
            this.v$.$validate();
            var fields = {};
            fields["admin_id"] = this.admin_id;
            fields["meraneta_url"] = this.meraneta_url;
            fields["userId"] = this.adminEmployees.value;
            fields["userlabel"] = this.adminEmployees.label;

            this.ApiService.getLoginAsadmin(fields).then((items) => {
                if (items.status == 200) {
                    this.showLoader = false;
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                }
            });
        },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (!this.officemobileno && !+$event.key) {
                $event.preventDefault();
            }
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        editadminDetailsRedirect(data) {
            router.push({
                name: "admindashboard",
                params: { adminId: data.maa1 },
            });
        },
        validateUrl(url) {
            this.errStatus = false;
            if (!this.validUrl(url)) {
                this.systemUrlErr = "Please enter a valid URL";
                this.errStatus = true;
            }
            if (this.errStatus === false) {
                this.systemUrlErr = "";
                return true;
            }
        },

        validUrl: function (url) {
            var re = /^(?!((ftp|http(s?)):\/\/))?[^ "]+(\.[a-zA-Z]{2,})+$/;
            return re.test(url);
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep(.p-progressbar) {
    height: 1rem;
    background-color: #d8dadc;
}

/* Responsive */
.p-datatable-users .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-progressbar-value.ui-widget-header {
    background: #607d8b;
}

@media (max-width: 640px) {
    .p-progressbar {
        margin-top: 0.5rem;
    }
}

.orders-subtable {
    padding: 1rem;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-users {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid var(--surface-d);

                >td {
                    text-align: left !important;
                    display: block;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                        display: inline-block;
                        width: 60%;
                    }

                    .p-rating {
                        display: inline-block;
                    }
                }
            }

            .p-datatable-tbody>tr.p-rowgroup-footer {
                display: flex;
            }
        }
    }
}

.true-icon {
    color: #256029;
}

.false-icon {
    color: #c63737;
}

.document-received-image {
    max-width: 80px;
    width: 100%;
    display: block;
    cursor: pointer;
}

.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
